const StaffRouter = []

if (window.location.pathname.search("/staff") != -1){
  StaffRouter.push(
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: 'home',
        name: 'Home',
        component: () => import('../views/staff/Home.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Home' }
          ]
        }
      },
      {
        path: 'new-projects',
        name: 'NewProjects',
        component: () => import('../views/staff/Projects/NewProjects.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'NewProjects' }
          ]
        }
      },
      {
        
        path: 'demo-testing',
        name: 'DemoTesting',
        component: () => import('../views/staff/Projects/NewProjects.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'NewProjects' }
          ]
        }
      },
      {
        path: 'veryfing-professional-projects',
        name: 'veryfication',
        component: () => import('../views/staff/veryfication/veryfication.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'veryfication' }
          ]
        }
      },
    
      {
        path: 'assign-projects',
        name: 'AssignProject',
        component: () => import('../views/staff/Projects/AssignProject.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AssignProject' }
          ]
        }
      },
      {
        path: 'project-detailes/:id',
        name: 'ProjectDetails',
        component: () => import('../views/staff/Projects/ProjectDetails.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProjectDetails' }
          ]
        }
      },
      {
        path: 'admin-kyc-approve',
        name: 'AdminKycApprove',
        component: () => import('../views/staff/ApproveKyc/AdminKycApprove.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminKycApprove' }
          ]
        }
      },

      {
        path: 'admin-kyc',
        name: 'AdminKyc',
        component: () => import('../views/staff/Kyc/AdminKyc.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminKyc' }
          ]
        }
      },
      {
        path: 'professional-kyc',
        name: 'ProfessionalKyc',
        component: () => import('../views/staff/Kyc/ProfessionalKyc.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalKyc' }
          ]
        }
      },
      {
        path: 'retailer-transections-staff/:id',
        name: 'RetailerTransectionsStaff',
        component: () => import('../views/staff/transection/transection.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerTransectionsStaff' }
          ]
        }
      },
      {
        path: 'admin-transections-staff/:id',
        name: 'AdmintransectionStaff',
        component: () => import('../views/staff/transection/Admintransection.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdmintransectionStaff' }
          ]
        }
      },
      {
        path: 'professional-transections-staff/:id',
        name: 'Professionaltransection',
        component: () => import('../views/staff/transection/Professionaltransection.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Professionaltransection' }
          ]
        }
      },

      {
        path: 'distributer-transections-staff/:id',
        name: 'Distributertransection',
        component: () => import('../views/staff/transection/Distributertransection.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Distributertransection' }
          ]
        }
      },
      {
        path: 'master-distributer-transections-staff/:id',
        name: 'MasterDistributertransection',
        component: () => import('../views/staff/transection/MasterDistributertransection.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributertransection' }
          ]
        }
      },
      {
        path: 'retailer-tds/:id',
        name: 'RetailerTds',
        component: () => import('../views/staff/transection/tdsReport.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerTds' }
          ]
        }
      },
      {
        path: 'admin-tds/:id',
        name: 'AdminTds',
        component: () => import('../views/staff/transection/AdmintdsReport.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminTds' }
          ]
        }
      },
      {
        path: 'professional-tds/:id',
        name: 'ProfessionalTds',
        component: () => import('../views/staff/transection/ProfessionaltdsReport.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalTds' }
          ]
        }
      },
      {
        path: 'distributer-tds/:id',
        name: 'DistributerTds',
        component: () => import('../views/staff/transection/DistributertdsReport.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'DistributerTds' }
          ]
        }
      },
      {
        path: 'master-distributer-tds/:id',
        name: 'MasterDistributertdsReport',
        component: () => import('../views/staff/transection/MasterDistributertdsReport.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributertdsReport' }
          ]
        }
      },
      {
        path: 'clients',
        name: 'Clients',
        component: () => import('../views/staff/Clients/Clients.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Clients' }
          ]
        }
      },
      
      {
        path: 'clients-profile/:id',
        name: 'ClientProfile',
        component: () => import('../views/staff/Clients/ClientProfile.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ClientProfile' }
          ]
        }
      },
      {
        path: 'ticket',
        name: 'ticket',
        component: () => import('../views/staff/ticket/ticket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ticket' }
          ]
        }
      },
      {
        path: 'ticket-reply/:id',
        name: 'TicketReply',
        component: () => import('../views/staff/ticket/viewTicket.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'TicketReply' }
          ]
        }
      },
      {
        path: 'view-master-distributer/:id',
        name: 'MasterDistributerViewStaff',
        component: () => import('../views/staff/Profile/MasterDistributerProfile.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributerViewStaff' }
          ]
        }
      },
      {
        path: 'view-distributer/:id',
        name: 'DistributerViewStaff',
        component: () => import('../views/staff/Profile/DistributersView.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'DistributerViewStaff' }
          ]
        }
      },
      {
        path: 'admins-staff',
        name: 'AdminStaff',
        component: () => import('../views/staff/users/admin.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminStaff' }
          ]
        }
      },
      {
        path: 'staff-services',
        name: 'StaffServices',
        component: () => import('../views/staff/Services/StaffServices.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'StaffServices' }
          ]
        }
      },
        {
        path: 'professional-staff',
        name: 'ProfessionalStaff',
        component: () => import('../views/staff/users/professional.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalStaff' }
          ]
        }
      },
      {
        path: 'distributer-staff',
        name: 'DistributerStaff',
        component: () => import('../views/staff/users/distributer.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'DistributerStaff' }
          ]
        }
      },
      {
        path: 'master-distributer-staff',
        name: 'MasterDistributerStaff',
        component: () => import('../views/staff/users/masterDistributer.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'MasterDistributerStaff' }
          ]
        }
      },
      {
        path: 'retailer-staff',
        name: 'RetailerStaff',
        component: () => import('../views/staff/users/retailer.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerStaff' }
          ]
        }
      },
      {
        path: 'staffs',
        name: 'Staffget',
        component: () => import('../views/staff/users/staff.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Staffget' }
          ]
        }
      },
      {
        path: 'upgrades',
        name: 'upgrades',
        component: () => import('../views/staff/upgrades/upgrads.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'upgrades' }
          ]
        }
      },
      {
        path: 'bussiness-doccument-varification',
        name: 'businessDoccument',
        component: () => import('../views/staff/businessDoccument/doccument.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'businessDoccument' }
          ]
        }
      },
      {
        path: 'challan',
        name: 'Challan',
        component: () => import('../views/staff/challan/challan.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Challan' }
          ]
        }
      },
      {
        path: 'certificate',
        name: 'staffCcertificate',
        component: () => import('../views/staff/Certificate/certificate.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'staffCcertificate' }
          ]
        }
      },
      {
        path: 'retailer-kyc',
        name: 'RetailerKyc',
        component: () => import('../views/staff/Kyc/RetailerKyc.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerKyc' }
          ]
        }
      },
      {
        path: 'professional-kyc-approve',
        name: 'ProfessionalKycApprove',
        component: () => import('../views/staff/ApproveKyc/ProfessionalKycApprove.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalKycApprove' }
          ]
        }
      },
      {
        path: 'retailer-kyc-approve',
        name: 'RetailerKycApprove',
        component: () => import('../views/staff/ApproveKyc/RetailerKycApprove.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerKycApprove' }
          ]
        }
      },
      {
        path: 'profile-page',
        name: 'Profile',
        component: () => import('../views/staff/Profile/Profile.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'Profile' }
          ]
        }
      },
      {
        path: 'professional-profile-view/:id',
        name: 'ProfessionalProfileView',
        component: () => import('../views/staff/Profile/ProfessionalProfileView.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'ProfessionalProfileView.' }
          ]
        }
      },
      {
        path: 'staff-view-data/:id',
        name: 'staffDataDetail',
        component: () => import('../views/staff/Profile/staffDataDetail.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'staffDataDetail' }
          ]
        }
      },
      {
        path: 'admin-profile-view/:id',
        name: 'AdminProfileView',
        component: () => import('../views/staff/Profile/AdminProfileView.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'AdminProfileView.' }
          ]
        }
      },
      {
        path: 'retailer-profile-view/:id',
        name: 'RetailerProfileView',
        component: () => import('../views/staff/Profile/RetailerProfileView.vue'),
        meta: {
        //   title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'RetailerProfileView.' }
          ]
        }
      },
 {
        path: 'learning-center',
        name: 'learningcenter',
        component: () => import('../views/staff/learningcenter/LearningCenter.vue'),
        meta: {
          // title: 'home title',
          redirectIfLoggedIn: 'true',
          breadCrumb: [
            { name: 'learningcenters' }
          ]
        }
      },
      {
      path: 'staff-leads',
      name: 'StaffLeads',
      component: () => import('../views/staff/Leads/Leads.vue'),
      meta: {
        // title: 'home title',
        redirectIfLoggedIn: 'true',
        breadCrumb: [
          { name: 'StaffLeads' }
        ]
      }
    },
      // {
      //   path: 'login-staff',
      //   name: 'LoginForm',
      //  component: () => import('../views/staff/auth/LoginForm.vue'),
      //   meta: {
      //   //   title: 'home title',
      //     redirectIfLoggedIn: 'true',
      //     breadCrumb: [
      //       { name: 'LoginForm.' }
      //     ]
      //   }
      // },
      // {
      //   path: 'login', name: 'Login',
      //   component: () => import('../views/staff/auth/LoginForm.vue'),
      //   meta: { headerVisible: 'hide' }
      // },

      {
        path: 'resetpassword', name: 'ResetPassword',
        component: () => import('../views/staff/auth/ResetPassword.vue'),
        meta: { headerVisible: 'hide' }
      },
      {
        path: 'login', name: 'Login',
        component: () => import('../views/staff/auth/Login.vue'),
        meta: { headerVisible: 'hide' }
      },
      {
        path: 'forgotpassword', name: 'ForgotPassword',
        component: () => import('../views/staff/auth/ForgotPassword.vue'),
        meta: { headerVisible: 'hide' }
      },
      // {
      //   path: 'resetpassword/:random', name: 'ResetPassword',
      //   component: () => import('../views/staff/auth/ResetPassword.vue'),
      //   meta: { headerVisible: 'hide' }
      // },
      {
        path: 'twostepverification', name: 'TwoStepVerification',
        component: () => import('../views/staff/auth/TwoStepVerification.vue'),
        meta: { headerVisible: 'hide' }
      }

    )

  }

  export default StaffRouter