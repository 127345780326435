<template>
  <!-- BEGIN: Main Menu-->
  <div
    class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
    data-scroll-to-active="true"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item me-auto">
          <router-link class="navbar-brand" to="/master-distributer/home">
            <span class="brand-logo">
              <img
                v-if="show == 'false'"
                src="/app-assets/images/vakilgiri-logo.png"
                style="max-width: 120px"
              />
              <img v-else src="/assets/image/fab2.png" style="max-width: 30px" />
            </span>
          </router-link>
        </li>
        <!-- @click="show == 'false' ? logoIconeChange('true') : logoIconeChange('false')" -->
        <li class="nav-item nav-toggle">
          <a
            class="nav-link modern-nav-toggle pe-0"
            data-bs-toggle="collapse"
            
          >
            <img
              class="collapse-toggle-icon font-medium-4 text-primary"
              style="width: 20px"
              src="/assets/image/more.png"
              alt=""
            />
          </a>
        </li>
      </ul>
    </div>
    <div class="shadow-bottom"></div>
    <div class="main-menu-content">
      <ul
        class="navigation navigation-main"
        id="main-menu-navigation"
        data-menu="menu-navigation"
      >
   

        

        <li class="nav-item" :class="{ 'active': $route.path === '/master-distributer/home' }">
          <router-link class="d-flex align-items-center" to="/master-distributer/home">
            <img style="width: 20px" :src="($route.path === '/master-distributer/home') ? '/assets/image/active-icon/dashboard.png' : '/assets/image/dashboard.png'" alt="image" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Dashboard</span>
          </router-link>
        </li>
    

        <li class="nav-item"  :class="{ 'active': $route.path === '/master-distributer/distributers' }">
          <router-link class="d-flex align-items-center" to="/master-distributer/distributers">
            <img style="width: 20px"  :src="($route.path === '/master-distributer/distributers') ? '/assets/image/active-icon/distributer.png' : '/assets/image/distributer.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Disatributer</span>
          </router-link>
        </li>

       
      
        <li class="nav-item" :class="{ 'active': $route.path === '/master-distributer/retailers' }">
          <router-link class="d-flex align-items-center" to="/master-distributer/retailers">
            <img style="width: 20px"  :src="($route.path === '/master-distributer/retailers') ? '/assets/image/active-icon/retailer.png' : '/assets/image/retailer.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Retailers</span>
          </router-link>
        </li>


    

        <li class="nav-item" :class="{ 'active': $route.path === '/master-distributer/projects' }">
          <router-link class="d-flex align-items-center" to="/master-distributer/projects">
            <img style="width: 20px"  :src="($route.path === '/master-distributer/projects') ? '/assets/image/active-icon/project.png' : '/assets/image/project.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"> Projects</span>
          </router-link>
        </li>
       
      
  
        <li class="nav-item" :class="{ 'active': $route.path === '/master-distributer/tds-reporting' }">
          <router-link class="d-flex align-items-center" to="/master-distributer/tds-reporting">
            <img style="width: 20px" :src="($route.path === '/master-distributer/tds-reporting') ? '/assets/image/active-icon/tds.png' : '/assets/image/tds.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">TDS</span>
          </router-link>
        </li>

       

        <li class="nav-item" :class="{ 'active': $route.path === '/master-distributer/service-charges' }">
          <router-link class="d-flex align-items-center" to="/master-distributer/service-charges">
            <img style="width: 20px"  :src="($route.path === '/master-distributer/service-charges') ? '/assets/image/active-icon/services.png' : '/assets/image/services.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Services</span>
          </router-link>
        </li>
       




     
        <li class="nav-item" :class="{ 'active': $route.path === '/master-distributer/ticket' }">
          <router-link class="d-flex align-items-center" to="/master-distributer/ticket">
            <img style="width: 20px"  :src="($route.path === '/master-distributer/ticket') ? '/assets/image/active-icon/tickets.png' : '/assets/image/tickets.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home">Ticket</span>
          </router-link>
        </li>

     


        <li class="nav-item" :class="{ 'active': $route.path === '/master-distributer/transections-reporting' }">
          <router-link
            class="d-flex align-items-center"
            to="/master-distributer/transections-reporting"
          >
            <img style="width: 20px" :src="($route.path === '/master-distributer/transections-reporting') ? '/assets/image/active-icon/transections-reporting.png' : '/assets/image/transections-reporting.png'" alt="" />
            &nbsp; &nbsp;&nbsp;&nbsp;
            <span class="menu-title text-truncate" data-i18n="Home"
              >Transactions & Reportings</span
            >
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <!-- END: Main Menu-->

  <span v-if="$route.path == '/master-distributer/'">
    {{ pageRedirectToHomeRoute() }}
  </span>
</template>
<script>
export default {
  name: "MasterDistributerSidebar",
  data() {
    return {
      portal: "",
      show: "false",
    };
  },
  methods: {
    logoIconeChange(value) {
      this.show = value;
    },
    pageRedirectToHomeRoute(){
      this.$router.push("/master-distributer/home");
    }
  },
  created() {
    // alert(localStorage.getItem("portal"));
    this.portal = localStorage.getItem("portal");
  },
};
</script>

<style scoped>
.main-menu.menu-light .navigation > li ul .active {
  color: #00364f !important;
  background-color: #f21300 !important;
  box-shadow: none !important;
}


.main-menu.menu-light .navigation > li.active > a {
    background: #f21300;
    box-shadow: none !important;
    color: #fff;
    font-weight: 400;
    border-radius: 4px;
}

.nav-item {
  font-family: "Poppins", sans-serif;
}


.nav-item.active {
  background-color: #f21000;
  color: white;
  box-shadow: 0px 0px 0px 0px solid white;
}

.nav-item.active a{
  background-color: #f21000;
  color: white;
  box-shadow: none;
}

.main-menu.menu-light .navigation li a {
  color: #00364f;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
}



.main-menu:not(.expanded) .navigation li.active a {
    background: #f21000 !important;
    /* box-shadow: none; */
    color: #ffffff;
}

</style>
