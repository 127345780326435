// import Vue from 'vue'
// import * as Vue from 'vue'
import axios from 'axios'

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import VueLodash from 'vue-lodash'
// import lodash from 'lodash'

import { library } from "@fortawesome/fontawesome-svg-core"
import { faArrowLeft, faBell, faBook, faCheck, faCheckDouble, faCircle, faClock, faCogs, faCreditCard, faEdit, faExclamationCircle, faEye, faFile, faFilter, faFloppyDisk, faHandPointer, faHome, faLink, faList, faMoneyBill, faPhone, faPlus, faRemove, faSearch, faShoppingBag,  faTag,  faThList,  faThumbsUp,  faTrash, faUserCircle, faWrench } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
library.add(faPhone, faHome, faArrowLeft, faTrash, faEdit, faSearch, faEye, faFilter,
    faCheck, faRemove, faClock, faExclamationCircle, faCheckDouble, faBell, faCircle, faUserCircle, faWrench, faShoppingBag, faMoneyBill, faBook, faThList, faCogs, faPlus,faFile ,faCreditCard, faList, faFloppyDisk, faLink, faThumbsUp, faTag,faHandPointer)

import { defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
// window.swal = VueSweetalert2;
// const options = {
//   confirmButtonColor: '#41b882',
//   cancelButtonColor: '#ff7674',
// };
// Vue.use(VueSweetalert2, options);

// Vue.config.productionTip = false
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://csc.vakilgiri.com/backend/api/v1/'
// axios.defaults.baseURL = 'https://legalcsc.microfinancesoftware.in/backend/api/v1/'
// axios.defaults.baseURL = 'https://uat.vakilgiri.com/backend/api/v1/'


// first url server
// axios.defaults.baseURL = 'http://101.53.135.8/backuend/api/v1/'
// second server
// axios.defaults.baseURL = 'http://101.53.135.8/backend/api/v1/'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1/'
if(localStorage.taken){
    // axios.defaults.headers.common.Authorization = `Bearer ${localStorage.accessToken}`
    // Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;
}
axios.defaults.headers.post.Accept = 'application/json'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// window.$ = window.jQuery = require('jquery')

// export default axios.create({
//     baseURL: "http://127.0.0.1:8000/api/v1/",
//     headers: {
//       "Content-type": "application/json"
//     }
//   })

// const app = Vue.createApp(App);
// app.config.globalProperties.$axios = axios;
// app.use(store).use(router).mount('#app')
// app.provide('$axios', axios)

// Vue.prototype.$http = axios
// Vue.prototype.$axios = axios
// import Form from 'vform'
// window.Form = Form
// Vue.component(HasError.name, HasError)
// Vue.component(AlertError.name, AlertError)
// createApp(App).use(store).use(router).mount('#app')


// window.$ = window.jQuery = require("jquery");
import jQuery from "jquery";
window.$ = jQuery
window.jQuery = jQuery

// for project routes 




const app = createApp(App).use(store).use(router)
app.config.globalProperties.$axios = axios
app.config.globalProperties.$http = axios


// app.config.globalProperties.$Swal = VueSweetalert2;
// app.use(VueLodash, { name: 'custom' , lodash: lodash })
app.use(VueSweetalert2);
app.component("font-awesome-icon", FontAwesomeIcon)
// app.component('ValidationObserver', ValidationObserver)
// app.component('ValidationProvider', ValidationProvider)
app.mount("#app")

