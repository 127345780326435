import { createStore } from 'vuex'

export default createStore({
  state: {
    pdfIconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/400px-PDF_file_icon.svg.png',

      // imgUrl: 'https://uat.vakilgiri.com/backend/system/public/storage',
      //      invoiceUrl: 'https://uat.vakilgiri.com/backend/api/v1/',


            imgUrl: 'https://csc.vakilgiri.com/backend/system/public/storage',
           invoiceUrl: 'https://csc.vakilgiri.com/backend/api/v1/',

    placeholderImg: '/assets/image/placeholder.png',
    settings: {
      name: 'Mohit Singh',
      email: 'mohit@gmail.com',
      phone: '+91-9898989898',
      address: 'Agra',
      state: 'Agra',
      city: 'UP',
      pin: '282010',
      companyName: 'LEGAL CSC PRIVATE LIMITED',
      companySortName: 'LEGAL CSC',
      companyIntroduction: 'LEGAL CSC PRIVATE LIMITED LEGAL CSC PRIVATE LIMITED LEGAL CSC PRIVATE LIMITED LEGAL CSC PRIVATE LIMITED LEGAL CSC PRIVATE LIMITED',
      companyEmail: 'info@legalcsc.com',
      companyPhone: '+91-9870797127',
      companyMobile: '+91-9870797127',
      companyWebsite: 'https://www.legalcsc.com',
      companyLogo: '',
      companyPdfLogo: '',
      companyFaviconIcon: '',
      companyAddress: 'D-11, Pratap Nagar, Lohamandi',
      companyState: 'Agra',
      companyCity: 'UP',
      companyPin: '282010',
      currencySymbol: '₹',
      dateFormate: 'DD/MM/YYYY',
      smsApiUrl: 'https://www.smsapi.com/?text=',
      smsApiEnable: 'true',
      saleName: 'Mohit Singh',
      saleEmail: 'sale@legalcsc.com',
      salePhone: '+91-9870797127',
      supportName: 'Karan Garg',
      supportEmail: 'support@legalcsc.com',
      supportPhone: '+91-9870797127',
      technicalName: 'Manoj Kumar Sharma',
      technicalEmail: 'tech@legalcsc.com',
      technicalPhone: '+91-9870797127',
      tollfree: '1800 2345 3434',
    }, 
    adminPageTitles: {
    dashboard: 'Welcome to the admin dashboard',

    clientsService: 'Client Services',
    clientsServiceView: 'Client Services View',
    
    mstDistributer: 'Master Distributers',
    mstDistributerView: 'Master Distributers View',

   distributer: ' Distributers',
   distributerView: ' Distributers View',

   retailer: ' Retailers',
   retailerView: ' Retailer View',


   ticket: ' Tickets',
   ticketView: ' Ticket View',

   client: ' Clients',
   clientView: ' Client View',


   serviceCharge: ' Service Charges',
   package: ' Packages',
   trasnectionReporting: 'Transection And Reporting ',



  },

  superAdminPageTitles: {
    login: 'Login SuperAdmin',
    dashboard: 'Welcome to the SuperAdmin Dashboard',

    clientsService: 'Client Services',
    clientsServiceView: 'Client Services View',
    
    mstDistributer: 'Master Distributers',
    mstDistributerView: 'Master Distributers Profile',

   distributer: ' Distributers',
   distributerView: ' Distributers Profile',
   
   adminCreate: ' Admin Create & Update',
   admin: ' Admins',
   adminView: ' Admin Profile',

   staff: ' Staff',
   staffView: ' Staff Profile',

   professional: ' Professionals',
   professionalView: ' Professional View',


   retailer: ' Retailers',
   retailerView: ' Retailer View',

   package: ' Packages',
   service: ' Sevices',
   addons: ' Addons',

   
   client: ' Clients',
   clientView: ' Client Profile',


   profile: 'Super Admin  Profile ',



   ticket: ' Tickets',
   ticketView: ' Ticket View',


   withdrawles : 'Wallet Withdraw Request ',
   serviceCharge: ' Service Charges',
 
   trasnectionReporting: 'Transection And Reporting ',



  //  MASTER




  category: ' Categories',

  steps: 'Steps',

  modules: 'Modules',

  permissions: 'Permissions',

  states: 'States',
  bussinessType: 'Bussiness Type',

  designation: 'Designation',

  onBoard: 'On Boarding Charge',
  professionalType: 'Professional Type',
  qualification: 'Qualifications',
  departments : 'Departments ',


  },
  staffPageTitles: {
    
    dashboard: 'Welcome to the Staff dashboard',

    clientsService: 'Client Services',
    clientsServiceView: 'Client Services View',
    
    mstDistributer: 'Master Distributers',
    mstDistributerView: 'Master Distributers Profile',

   distributer: ' Distributers',
   distributerView: ' Distributers Profile',
   
   upgrade: ' Upgrades',
   bussinessDocc: ' Business Documents',
   admin: ' Admins',
   adminView: ' Admin Profile',

   staff: ' Staff',
   staffView: ' Staff Profile',

   professional: ' Professionals',
   professionalView: ' Professional View',


   retailer: ' Retailers',
   retailerView: ' Retailer View',

 
   client: ' Clients',
   clientView: ' Client Profile',


   challans: 'Challans ',
   verification : 'Verifications ',



   ticket: ' Tickets',
   ticketView: ' Ticket View',


   withdrawles : 'Wallet Withdraw Request ',
   serviceCharge: ' Service Charges',
 
   trasnectionReporting: 'Transection And Reporting ',

   Certificate : 'Certificate ',

  },
  professionalPageTitles: {
    dashboard: 'Welcome to the Professional Dashboard',

    clientsService: 'Client Services',
    clientsServiceView: 'Client Services View',
    
 
   upgrade: ' Upgrades',
    
   Invoice: ' Invoices',
  
   profile: ' Professional View',

   challans: 'Challans ',
   verification : 'Verifications ',
   Certificate : 'Certificate ',



   ticket: ' Tickets',
   ticketView: ' Ticket View',


   withdrawles : 'Wallet Withdraw Request ',
   service: ' Services',
 
   trasnectionReporting: 'Transection And Reporting ',



  },
  retailerPageTitles: {
    dashboard: 'Welcome to the Retailer Dashboard',

    clientsService: 'Client Services',
    clientsServiceView: 'Client Services View',
    
   client: ' Clients',
   clientView: ' Client Profile',

    
   Invoice: ' Invoices',
   InvoiceView: ' Invoices View',
  
   profile: 'Profile',





   ticket: ' Tickets',
   ticketView: ' Ticket View',


   withdrawles : 'Wallet Withdraw Request ',
   service: ' Services',
   package: ' Packages',
 
   trasnectionReporting: 'Transection And Reporting ',
  },
  masterDistributerPageTitles: {
    dashboard: 'Welcome to the MD dashboard',
    
   profile: 'Master Distributer  Profile ',

   withdrawles : 'Wallet Withdraw Request ',
    clientsService: 'Projects',
    distributer: 'Distributer',
    distributerProfile: 'Distributer Profile',
    retailers: 'Retailers',
    retailerProfile: 'Retailer Profile',
    projects: 'Projects',
    service: 'Service',
    ticket: 'Ticket',
    tdsReport: 'TDS Report',
    trasnectionReporting: 'Transection And Reporting ',

  },

  distributerPageTitles: {
    dashboard: 'Welcome to the Distributer dashboard',
    clientsService: 'Client Services',

    ticket: ' Tickets',
    ticketView: ' Ticket View',

    
   retailer: ' Retailers',
   retailerView: ' Retailer View',
    
   trasnectionReporting: 'Transection And Reporting ',


   
   tds: 'TDS Reporting ',
   
  },



loginPageImageConent:{
imgh1 : 'Lorem, ipsum dolor.',
  img1 : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, voluptate odit! Sint suscipit enim iste facere vero debitis provident ratione temporibus nihil natus, at quos ducimus tempora aliquam aliquid deserunt',
  
  imgh2 : 'Lorem, ipsum dolor.',
  img2 : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, voluptate odit! Sint suscipit enim iste facere vero debitis provident ratione temporibus nihil natus, at quos ducimus tempora aliquam aliquid deserunt',
  
  imgh3 : 'Lorem, ipsum dolor.',
  img3 : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita, voluptate odit! Sint suscipit enim iste facere vero debitis provident ratione temporibus nihil natus, at quos ducimus tempora aliquam aliquid deserunt',


}






  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },



 
})